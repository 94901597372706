/**
 * Custom JS
 *
 * @package Banyan Master Theme
 * @since Banyan Master Theme 1.0
 */

(function($) {

	document.documentElement.classList.remove("no-js");
	document.documentElement.classList.add("js");

	/**
	 * foldable content
	 *
	 */
	$('.foldable-title').on('click', function() {
		if ( $(this).parent().hasClass('open') ) {
			$(this).next('.foldable-content').hide();
			$(this).parent().removeClass('open');
		} else {
			$(this).parent().addClass('open');
			$(this).next('.foldable-content').fadeIn(500).show();
		}
	});

    /**
	 * Clear Vik time selector on load
	 */
    const dateSelectors = $('.vrisfentrydate').find('input');
    for( const item of dateSelectors ){
        item.required = true;
    }

    const timeSelectors = $('.vrisfentrymodtime').find('select');
    for( const item of timeSelectors ){
        item.required = true;
        $(item).prepend('<option disabled selected value>--</option>');
    }

    const dateSelectorsPage = $('.vri-sf-input-wrap ').find('input');
    for( const item of dateSelectorsPage ){
        item.required = true;
    }

    const timeSelectorsPage = $('.vrisfentry').find('select');
    for( const item of timeSelectorsPage ){
        item.required = true;
        $(item).prepend('<option disabled selected value>--</option>');
    }

})( jQuery );
